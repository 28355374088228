/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
@media (min-width: 768px) {
  .grid.grid_cols_2 {
    width: calc(100% + 32px);
  }
}

@media (min-width: 768px) {
  .grid.grid_cols_2 > .grid__item {
    width: calc(50% - 32px);
    margin-right: 32px;
  }
  .grid.grid_cols_2 > .grid__item:last-child {
    margin-right: 0;
  }
}

.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

/* Prevents IE11 from highlighting tiles in blue */
.leaflet-tile::selection {
  background: transparent;
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */
.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline: 0;
}

.leaflet-container a {
  color: #0078A8;
}

.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */
.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* general toolbar styles */
.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px "Lucida Console", Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

/* layers control */
.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}

.leaflet-control-layers-toggle {
  background-image: url(https://s3.entegral.net/media/global/leaflet-layers.png);
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(https://s3.entegral.net/media/global/leaflet-layers-2x.png);
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
.leaflet-default-icon-path {
  background-image: url(https://s3.entegral.net/media/global/leaflet-marker-icon.png);
}

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover {
  text-decoration: underline;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}

.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}

.leaflet-popup-content p {
  margin: 18px 0;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */
.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.button {
  display: inline-block;
  height: auto;
  min-height: 60px;
  line-height: 60px;
  text-decoration: none;
  text-align: center;
  padding: 0 48px;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  border: none;
  appearance: none;
  font-size: 16px;
  color: #fff;
  background: #ab3b3f;
}
.button:hover {
  color: #fff;
  background: #1a1a1a;
}
.button_size_small {
  height: auto;
  min-height: 40px;
  line-height: 40px;
  padding: 0 24px;
}
.button_width_full {
  width: 100%;
}

.favorite-toggle > svg:nth-child(2) {
  display: none;
}

.favorite-toggle.favorite-toggle_state_active > svg:nth-child(1) {
  display: none;
}
.favorite-toggle.favorite-toggle_state_active > svg:nth-child(2) {
  display: inline-block;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.autocomplete {
  position: relative;
  color: #000;
}
.autocomplete__input {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 48px;
  border: 2px solid #c3c3c4;
  border-radius: 4px;
  background: #fff;
  padding: 12px 10px;
}
.autocomplete__input > input {
  padding: 0;
  outline: 0;
  border: 0;
  background: transparent;
  flex: 100;
  width: 100%;
  min-width: 100px;
  font-size: inherit;
}
.autocomplete__selected {
  color: #fff;
  background: #ab3b3f;
  margin-right: 8px;
  margin-bottom: 4px;
  padding: 2px 0 2px 8px;
  border-radius: 2px;
}
.autocomplete__remove {
  display: inline-block;
  cursor: pointer;
  padding: 0 8px;
}
.autocomplete__results {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.autocomplete__result {
  cursor: pointer;
  padding: 12px 10px;
  color: #000;
}
.autocomplete__result:hover {
  color: #000;
  background: #e6e6e6;
}
.autocomplete__result_town, .autocomplete__result_type_town {
  font-weight: bold;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.checkbox-list {
  position: relative;
  color: #000;
}
.checkbox-list__text {
  position: relative;
  min-height: 32px;
  line-height: 32px;
  padding: 7px 10px;
  border-radius: 3px;
  border: 2px solid #c3c3c4;
  background: #fff;
  cursor: pointer;
}
.checkbox-list__text:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23000000"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>');
  width: 8px;
  height: 16px;
  right: 20px;
}
.checkbox-list__count {
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 2px;
  margin-left: 4px;
  color: #fff;
  background: #ab3b3f;
}
.checkbox-list__options {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.checkbox-list__option {
  position: relative;
  cursor: pointer;
  padding: 12px 10px;
}
.checkbox-list__box {
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  width: 16px;
  height: 16px;
  border: 1px solid #c3c3c4;
  background: #fff;
  border-radius: 2px;
}
.checkbox-list__option.checkbox-list__option_group {
  font-weight: bold;
  text-transform: uppercase;
  cursor: initial;
}
.checkbox-list__option.checkbox-list__option_group > .checkbox-list__box {
  display: none;
}
.checkbox-list__option.checkbox-list__option_checked > .checkbox-list__box {
  border-color: #ab3b3f;
  background: #ab3b3f;
}
.checkbox-list_opened > .checkbox-list__options {
  display: block;
}
.checkbox-list_opened > .checkbox-list__text:after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23000000"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"/></svg>');
}

.dropdown {
  position: relative;
  color: #000;
}
.dropdown__value {
  position: relative;
  min-height: 32px;
  line-height: 32px;
  padding: 7px 40px 7px 12px;
  border-radius: 4px;
  border: 2px solid #c3c3c4;
  background: #fff;
  cursor: pointer;
}
.dropdown__value:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23000000"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>');
  width: 8px;
  height: 16px;
  right: 20px;
}
.dropdown__options {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.dropdown__option {
  cursor: pointer;
  padding: 12px;
  color: #000;
}
.dropdown__option:hover {
  color: #000;
  background: #e6e6e6;
}
.dropdown__option.dropdown__option_state_selected {
  color: #fff;
  background: #ab3b3f;
}
.dropdown_state_opened > .dropdown__value:after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23000000"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"/></svg>');
}
.dropdown_state_closed > .dropdown__options {
  display: none;
}
.dropdown_state_opened > .dropdown__options {
  display: block;
}

.switcher {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  color: #000;
}
.switcher__control {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 48px;
  height: 28px;
  border-radius: 16px;
  background-color: #efeeef;
  transition: background-color 0.3s ease;
}
.switcher__control:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  transition: background-color 0.3s ease, left 0.3s ease;
}
.switcher__label {
  display: inline-block;
  margin-left: 12px;
}
.switcher_state_checked > .switcher__control {
  background-color: #ab3b3f;
}
.switcher_state_checked > .switcher__control:after {
  left: 24px;
  background-color: #fff;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.search-bar {
  position: relative;
}
.search-bar__container {
  width: 280px;
  margin: 0 auto;
}
.search-bar__main-options {
  position: relative;
  padding: 16px;
  border-radius: 3px;
  background-color: #fff;
}
.search-bar__main-options .dropdown__value,
.search-bar__main-options .checkbox-list__text,
.search-bar__main-options .autocomplete__input {
  background-color: transparent;
  border: none;
  padding: 0;
}
.search-bar__main-options .autocomplete__input {
  min-height: 32px;
}
.search-bar__main-options .dropdown,
.search-bar__main-options .checkbox-list {
  border-bottom: 1px solid #ececec;
}
.search-bar__main-options .dropdown,
.search-bar__main-options .checkbox-list,
.search-bar__main-options .autocomplete {
  padding: 32px 0 16px;
}
.search-bar__main-options .dropdown:after,
.search-bar__main-options .checkbox-list:after,
.search-bar__main-options .autocomplete:after {
  position: absolute;
  top: 16px;
  left: 0;
  font-size: 11px;
  font-weight: bold;
  color: #000;
}
.search-bar__main-options .dropdown:after {
  content: "STATUS";
}
.search-bar__main-options .checkbox-list:after {
  content: "TYPE";
}
.search-bar__main-options .autocomplete:after {
  content: "LOCATION";
}
.search-bar__secondary-options {
  display: none;
  margin-top: 64px;
  padding: 16px 16px 0;
  background-color: #fff;
  border-radius: 3px;
  border-top: 4px solid #ab3b3f;
}
.search-bar__secondary-options .dropdown__value,
.search-bar__secondary-options .checkbox-list__text {
  border: none;
  background-color: #efefef;
  border-radius: 2px;
  min-height: 24px;
  line-height: 24px;
}
.search-bar__secondary-options .switcher {
  display: flex;
  margin-top: 16px;
}
.search-bar__secondary-options .dropdown__value,
.search-bar__secondary-options .dropdown__options,
.search-bar__secondary-options .checkbox-list__text,
.search-bar__secondary-options .checkbox-list__options,
.search-bar__secondary-options .switcher {
  font-size: 14px;
}
.search-bar__secondary-options .dropdown + .dropdown,
.search-bar__secondary-options .dropdown + .checkbox-list {
  margin-top: 12px;
}
.search-bar__secondary-options_state_open {
  display: block;
}
.search-bar__options {
  position: absolute;
  bottom: -32px;
  right: 0;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  border-bottom: 1px dotted #fff;
}
.search-bar__options:hover {
  color: #fff;
  border-color: #fff;
}
.search-bar__submit {
  padding: 16px 0;
  border-radius: 2px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-color: #ab3b3f;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.search-bar__submit:hover {
  color: #fff;
  background-color: #1a1a1a;
}
.search-bar .clearfix {
  display: block;
  width: 100%;
}
@media (min-width: 375px) {
  .search-bar__container {
    width: 320px;
  }
}
@media (min-width: 425px) {
  .search-bar__container {
    width: 350px;
  }
}
@media (min-width: 768px) {
  .search-bar__container {
    width: 720px;
  }
  .search-bar__main-options {
    display: flex;
    padding: 0 0 0 16px;
  }
  .search-bar__main-options .dropdown {
    flex-basis: 160px;
    margin-right: 16px;
  }
  .search-bar__main-options .checkbox-list {
    flex-basis: 180px;
    margin-right: 16px;
  }
  .search-bar__main-options .autocomplete {
    flex-basis: 240px;
  }
  .search-bar__main-options .dropdown,
.search-bar__main-options .checkbox-list {
    border-bottom: none;
  }
  .search-bar__main-options .dropdown:before,
.search-bar__main-options .checkbox-list:before {
    content: "";
    position: absolute;
    top: 24px;
    right: 0;
    width: 1px;
    height: 32px;
    border-right: 1px solid #ececec;
  }
  .search-bar__submit {
    flex-grow: 1;
    padding-top: 28px;
    border-radius: 0 3px 3px 0;
  }
  .search-bar__secondary-options_state_open {
    display: flex;
    flex-wrap: wrap;
  }
  .search-bar__secondary-options_state_open .dropdown,
.search-bar__secondary-options_state_open .checkbox-list {
    flex-basis: calc(50% - 8px);
    flex-shrink: 1;
    flex-grow: 0;
    margin-bottom: 16px;
    margin-right: 16px;
  }
  .search-bar__secondary-options_state_open .dropdown:nth-child(2n),
.search-bar__secondary-options_state_open .checkbox-list:nth-child(2n) {
    margin-right: 0;
  }
  .search-bar__secondary-options_state_open .dropdown + .dropdown,
.search-bar__secondary-options_state_open .dropdown + .checkbox-list {
    margin-top: 0;
  }
  .search-bar__secondary-options_state_open .switcher {
    margin-top: 0;
    margin-bottom: 16px;
    margin-right: 32px;
  }
}
@media (min-width: 1024px) {
  .search-bar__container {
    width: 920px;
  }
  .search-bar__main-options .checkbox-list {
    flex-basis: 225px;
  }
  .search-bar__main-options .autocomplete {
    flex-basis: 355px;
  }
  .search-bar__secondary-options_state_open {
    display: flex;
    flex-wrap: wrap;
  }
  .search-bar__secondary-options_state_open .dropdown,
.search-bar__secondary-options_state_open .checkbox-list {
    flex-basis: calc(25% - 12px);
    margin-right: 16px;
  }
  .search-bar__secondary-options_state_open .dropdown:nth-child(2n),
.search-bar__secondary-options_state_open .checkbox-list:nth-child(2n) {
    margin-right: 16px;
  }
  .search-bar__secondary-options_state_open .dropdown:nth-child(4n),
.search-bar__secondary-options_state_open .checkbox-list:nth-child(4n) {
    margin-right: 0;
  }
  .search-bar__options {
    right: 22px;
  }
}

.hero {
  position: relative;
}
.hero__heading, .hero__subheading {
  position: relative;
  padding: 0 16px;
  max-width: 540px;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  margin: 0 auto 48px;
}
.hero__heading + .hero__subheading {
  margin-top: -32px;
}
.hero__actions {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .hero__heading, .hero__subheading {
    max-width: 100%;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.gallery__canvas {
  position: relative;
}

.gallery__images, .gallery__image, .gallery__shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gallery__image {
  opacity: 0;
}

.gallery__image_with_transition {
  transition: opacity 0.3s ease;
}

.gallery__image_timeline_current {
  opacity: 1;
}

.gallery__shadow {
  background: rgba(0, 0, 0, 0.5);
}

.gallery__dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  width: 80%;
  text-align: center;
}

.gallery__dot {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
}

.gallery__dot_state_active {
  background-color: #221e1f;
}

.gallery__dot + .gallery__dot {
  margin-left: 8px;
}

.gallery__control {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  border-radius: 50%;
  background-color: #ab3b3f;
  cursor: pointer;
}

.gallery__control > svg {
  fill: #fff;
  width: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gallery__control:hover {
  background-color: #221e1f;
}

.gallery__control:hover > svg {
  fill: #fff;
}

.gallery__control_type_prev {
  left: 0;
  transform: translate(-25%, -50%);
}

.gallery__control_type_next {
  right: 0;
  transform: translate(25%, -50%);
}

.gallery[data-with-origin] .gallery__image {
  cursor: pointer;
}

.gallery__stripe {
  display: flex;
  margin-top: 12px;
  font-size: 14px;
  color: #000;
}

.gallery__title {
  flex: 1 1 0;
}

.gallery__counter {
  flex: 0 0 auto;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.hero__gallery {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero__gallery > .gallery__canvas {
  width: 100%;
  height: 100%;
}

.hero .gallery__dots {
  display: none;
}

.hero__heading + .hero__search-bar {
  margin-bottom: 48px;
  padding-top: 0;
}

.hero_type_gallery-and-search {
  width: 100%;
  flex-basis: 780px;
  flex-shrink: 0;
}

.hero_type_gallery-and-search > .hero__heading {
  text-align: center;
  font-size: 36px;
}

.hero_type_gallery-and-search > .hero__subheading {
  text-align: center;
  font-size: 24px;
  margin-bottom: 0;
}

@media (min-width: 375px) {
  .hero_type_gallery-and-search {
    flex-basis: 730px;
  }
}
@media (min-width: 425px) {
  .hero_type_gallery-and-search {
    flex-basis: 680px;
  }
}
@media (min-width: 768px) {
  .hero_type_gallery-and-search {
    flex-basis: 510px;
    padding-top: 150px;
  }

  .hero .gallery__dots {
    display: block;
  }
}
.hero_type_gallery-and-search {
  flex-basis: 740px;
}

.hero_type_gallery-and-search .hero__buttons {
  position: relative;
  text-align: center;
  margin-bottom: -16px;
  padding-top: 16px;
}

.hero_type_gallery-and-search .hero__buttons .button {
  margin-left: 16px;
  margin-bottom: 16px;
}

@media (min-width: 320px) {
  .hero_type_gallery-and-search {
    flex-basis: 640px;
  }
}
@media (min-width: 425px) {
  .hero_type_gallery-and-search {
    flex-basis: 530px;
  }
}
@media (min-width: 1024px) {
  .hero_type_gallery-and-search {
    flex-basis: 600px;
    padding-top: 210px;
  }
}
@media (min-width: 1200px) {
  .hero_type_gallery-and-search {
    padding-top: 220px;
  }
}
@media (min-width: 1600px) {
  .hero_type_gallery-and-search {
    flex-basis: 700px;
    padding-top: 300px;
  }
}
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.properties-review-map {
  position: relative;
  background-color: #ab3b3f;
}

.properties-review-map__container {
  height: 350px;
}

.properties-review-map__marker {
  width: 16px !important;
  height: 16px !important;
  margin-left: -8px !important;
  margin-top: -8px !important;
  color: #fff;
  border: 2px solid #fff;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.7) 1px 1px 3px 0;
  border-radius: 0;
}

.properties-review-map__marker_status_selling {
  background: #ab3b3f;
}

.properties-review-map__marker_status_rental {
  background: #343434;
}

.properties-review-map__marker_status_auction {
  background: #3D8028;
}

.properties-review-map__marker_status_undefined {
  background: #343434;
}

.properties-review-map__marker_status_mixed {
  background: #ab3b3f;
}

.properties-review-map__marker_status_mixed:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 16px;
  top: -2px;
  right: -2px;
  background: #343434;
  border: 2px solid #fff;
  box-sizing: border-box;
  border-left: none;
}

.properties-review-map__marker_location_exact {
  border-radius: 8px;
}

.properties-review-map__marker_location_exact.properties-review-map__marker_status_mixed:after {
  top: 0;
  right: 0;
  border-radius: 0 90px 90px 0;
  transform: rotate(45deg);
}

.properties-review-map__summary, .properties-review-map__caption {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 16px;
  width: max-content;
  background: #fff;
  color: #000;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.7) 1px 1px 3px 0;
}

.properties-review-map-details {
  height: 200px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.properties-review-map-details.properties-review-map_type_single {
  height: 170px;
}

.properties-review-map-details.properties-review-map_type_single .properties-review-map-detail {
  width: 225px;
}

.properties-review-map-detail {
  display: block;
  position: relative;
  margin-bottom: 16px;
  width: 235px;
  height: 165px;
}

.properties-review-map-detail_status_selling .properties-review-map-detail__heading {
  color: #fff;
  background: #ab3b3f;
}

.properties-review-map-detail_status_rental .properties-review-map-detail__heading {
  color: #fff;
  background: #343434;
}

.properties-review-map-detail_status_auction .properties-review-map-detail__heading {
  color: #fff;
  background: #3D8028;
}

.properties-review-map-detail_status_undefined .properties-review-map-detail__heading {
  color: #fff;
  background: #343434;
}

.properties-review-map-detail__heading {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 225px;
  background: #ab3b3f;
  color: #fff;
  padding: 4px 8px;
  box-sizing: border-box;
  border-radius: 0 0 2px 2px;
}

.properties-review-map-detail__image {
  width: 225px;
  height: 165px;
  border-radius: 2px;
  object-fit: cover;
  object-position: 50% 50%;
}

.properties-review-map-detail:last-child {
  margin-bottom: 0;
}

.properties-review-map__filter.checkbox-list, .properties-review-map__filter.range-dropdown {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 33.33%;
  height: 60px;
  margin: 0;
  padding: 24px 24px 0 8px;
  vertical-align: top;
  cursor: pointer;
}

.properties-review-map__filter.checkbox-list:after {
  content: "";
  position: absolute;
  top: 12px;
  right: 0;
  width: 1px;
  height: 36px;
  background: #fff;
}

.properties-review-map__filter.checkbox-list > .checkbox-list__options {
  width: 300%;
}

.properties-review-map__filter.range-dropdown > .range-dropdown__options {
  display: none;
  position: absolute;
  box-sizing: border-box;
  width: 300%;
  top: 60px;
  left: 0;
  max-height: 205px;
  z-index: 9;
  background: #fff;
  border: 1px solid #c3c3c4;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  cursor: initial;
}

.properties-review-map__filter.checkbox-list.checkbox-list_state_opened > .checkbox-list__options, .properties-review-map__filter.range-dropdown.range-dropdown_state_opened > .range-dropdown__options {
  display: block;
}

.properties-review-map__filter.checkbox-list > .checkbox-list__caption, .properties-review-map__filter.range-dropdown > .range-dropdown__caption {
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #fff;
}

.properties-review-map__filter.checkbox-list > .checkbox-list__caption:before, .properties-review-map__filter.range-dropdown > .range-dropdown__caption:before {
  position: absolute;
  top: 8px;
  left: 8px;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

.properties-review-map__filter.checkbox-list > .checkbox-list__caption:after, .properties-review-map__filter.range-dropdown > .range-dropdown__caption:after {
  content: "";
  position: absolute;
  top: 32px;
  right: 10px;
  width: 0;
  height: 0;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
}

.properties-review-map__filter.checkbox-list.checkbox-list_state_opened > .checkbox-list__caption:after, .properties-review-map__filter.range-dropdown.range-dropdown_state_opened > .range-dropdown__caption:after {
  border-bottom: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  border-top: none;
}

.properties-review-map__filter.checkbox-list .checkbox-list__option, .properties-review-map__filter.checkbox-list .checkbox-list__close, .properties-review-map__filter.range-dropdown .range-dropdown__close {
  position: relative;
  cursor: pointer;
  padding: 8px 40px 8px 12px;
  min-height: 40px;
}

.properties-review-map__filter.checkbox-list .checkbox-list__option.checkbox-list__option_state_checked > .checkbox-list__box {
  border-color: #ab3b3f;
  background: #ab3b3f;
}

.properties-review-map__filter.checkbox-list .checkbox-list__option.checkbox-list__option_state_checked.checkbox-list__option_type_selling > .checkbox-list__box {
  border-color: #ab3b3f;
  background: #ab3b3f;
}

.properties-review-map__filter.checkbox-list .checkbox-list__option.checkbox-list__option_state_checked.checkbox-list__option_type_rental > .checkbox-list__box {
  border-color: #343434;
  background: #343434;
}

.properties-review-map__filter.checkbox-list .checkbox-list__option.checkbox-list__option_state_checked.checkbox-list__option_type_auction > .checkbox-list__box {
  border-color: #3D8028;
  background: #3D8028;
}

.properties-review-map__filter.checkbox-list .checkbox-list__option.checkbox-list__option_state_checked.checkbox-list__option_type_undefined > .checkbox-list__box {
  border-color: #343434;
  background: #343434;
}

.properties-review-map__filter.checkbox-list .checkbox-list__close, .properties-review-map__filter.range-dropdown .range-dropdown__close {
  position: sticky;
  box-sizing: border-box;
  width: 100%;
  bottom: 0;
  left: 0;
  color: #fff;
  background-color: #ab3b3f;
  text-align: right;
  text-transform: uppercase;
  padding-right: 12px;
}

.properties-review-map__filter.range-dropdown .range-dropdown__textboxes {
  padding: 8px;
}

.properties-review-map__filter.properties-review-map__filter_type_type.checkbox-list > .checkbox-list__options {
  left: -100%;
}

.properties-review-map__filter.properties-review-map__filter_type_price.range-dropdown > .range-dropdown__options {
  left: -200%;
}

.properties-review-map__filter.properties-review-map__filter_type_status.checkbox-list > .checkbox-list__caption:before {
  content: "Status";
}

.properties-review-map__filter.properties-review-map__filter_type_type.checkbox-list > .checkbox-list__caption:before {
  content: "Type";
}

.properties-review-map__filter.properties-review-map__filter_type_price.range-dropdown > .range-dropdown__caption:before {
  content: "Price";
}

.properties-review-map__filter .textbox {
  display: inline-block;
  width: 200px;
}

.properties-review-map__filter .textbox__input {
  display: inline-block;
  width: 100%;
  border-radius: 2px;
  box-shadow: none;
  border: 1px solid #c3c3c4;
  padding: 8px 12px;
}

.properties-review-map__filter.range-dropdown .textbox.range-dropdown__textbox {
  width: 45%;
}

.properties-review-map__filter.range-dropdown .range-dropdown__textbox-splitter {
  display: inline-block;
  width: 10%;
  height: 46px;
  line-height: 46px;
  vertical-align: top;
  text-align: center;
}

.properties-review-map .legend {
  padding: 16px 8px 8px;
  background-color: #ececec;
}

.properties-review-map .legend__line {
  display: inline-block;
  line-height: 24px;
  margin-right: 8px;
}

.properties-review-map .legend__item {
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  top: 2px;
  background: #ab3b3f;
  box-sizing: border-box;
  border: 2px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 3px 0;
}

.properties-review-map .legend__item_location_exact {
  border-radius: 8px;
}

.properties-review-map .leaflet-pane {
  z-index: 5;
}

.properties-review-map .leaflet-bottom,
.properties-review-map .leaflet-top {
  z-index: 6;
}

.properties-review-map .leaflet-container:after {
  color: #fff;
  font-size: 24px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 15px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 8;
  pointer-events: none;
  text-align: center;
  transition: opacity 0.8s ease-in-out;
  opacity: 0;
  content: attr(data-gesture-handling-scroll-content);
}

.properties-review-map .leaflet-gesture-handling-scroll-warning:after {
  transition-duration: 0.3s;
  opacity: 1;
}

@media (min-width: 768px) {
  .properties-review-map__container {
    height: 600px;
  }

  .properties-review-map__filter.checkbox-list, .properties-review-map__filter.range-dropdown {
    width: 275px;
  }

  .properties-review-map__filter.properties-review-map__filter_type_type.checkbox-list > .checkbox-list__options, .properties-review-map__filter.properties-review-map__filter_type_price.range-dropdown > .range-dropdown__options {
    left: 0;
  }

  .properties-review-map__filter.checkbox-list > .checkbox-list__options, .properties-review-map__filter.range-dropdown > .range-dropdown__options {
    width: 100%;
  }
}
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.property-card {
  display: block;
  position: relative;
  text-decoration: none;
  color: #000;
}
.property-card__tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  padding-bottom: 8px;
}
.property-card__tag {
  color: #fff;
  background: #221e1f;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 2px;
  text-align: center;
  line-height: 20px;
}
.property-card__favorite {
  position: absolute;
  top: -6px;
  left: 24px;
  z-index: 2;
  width: 40px;
  height: 40px;
  border-radius: 0 0 2px 2px;
  color: #fff;
  background: #ab3b3f;
  cursor: pointer;
}
.property-card__favorite:after {
  content: "";
  position: absolute;
  top: 0;
  right: -6px;
  height: 0;
  width: 0;
  border-bottom: 6px solid #852e31;
  border-right: 6px solid transparent;
}
.property-card__favorite > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  fill: currentColor;
}
.property-card__favorite:hover {
  color: #fff;
  background: #ab3b3f;
}
.property-card__primary-details {
  padding: 8px 16px;
  color: #fff;
  background-color: #ab3b3f;
}
.property-card__price {
  font-size: 0;
}
.property-card__currency, .property-card__amount, .property-card__units, .property-card__period {
  font-size: 18px;
  font-weight: bold;
}
.property-card__amount, .property-card__units, .property-card__period {
  padding-left: 4px;
}
.property-card__summary {
  font-size: 14px;
}
.property-card__area {
  font-size: 14px;
}
.property-card__secondary-details {
  padding: 12px 16px;
}
.property-card__heading {
  font-size: 14px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.property-card__description {
  margin-top: 8px;
  font-size: 12px;
}
.property-card__features {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}
.property-card__feature {
  display: flex;
  flex: 0 0 auto;
  line-height: 40px;
  margin-right: 10px;
}
.property-card__feature:last-child {
  margin-right: 0;
}
.property-card__feature-icon {
  margin-right: 4px;
}
.property-card__feature-icon > svg {
  position: relative;
  top: -2px;
  width: 18px;
  vertical-align: middle;
  fill: #000;
}
.property-card__feature-value {
  font-size: 14px;
}
.property-card__feature_type_beds svg {
  top: -1px;
  width: 22px;
}
.property-card__feature_type_baths svg {
  width: 18px;
}
.property-card__feature_type_stand svg {
  width: 20px;
}
.property-card__active-area {
  position: relative;
  z-index: 1;
  height: 100%;
  background-color: #f6f7f9;
  border: 1px solid #efefef;
  border-radius: 4px;
  overflow: hidden;
}
.property-card__active-area:hover .property-card__primary-details {
  color: #fff;
  background-color: #221e1f;
}

.property-card__address {
  display: flex;
  margin-top: 12px;
  font-size: 14px;
  font-style: italic;
}
.property-card__address-icon {
  width: 16px;
  margin-right: 4px;
}

.property-card__address_type_country svg {
  position: relative;
  top: 1px;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.property-card__photos {
  position: relative;
}
.property-card__photo {
  position: relative;
  width: 100%;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.property-card__photo > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.property-card__control {
  position: absolute;
  top: 0;
  width: 60px;
  height: 100%;
}
.property-card__control > svg {
  width: 10px;
  fill: #fff;
  filter: drop-shadow(0rem 0.125rem 0.1875rem rgba(0, 0, 0, 0.25));
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.property-card__control:hover > svg {
  fill: #ab3b3f;
}
.property-card__control_type_prev {
  left: 0;
}
.property-card__control_type_prev > svg {
  left: 8px;
}
.property-card__control_type_next {
  right: 0;
}
.property-card__control_type_next > svg {
  right: 8px;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.tool-card {
  text-decoration: none;
}
.tool-card__icon {
  position: relative;
  top: 0;
  width: 50px;
  margin: 0 auto 16px;
  transition: top 0.3s ease;
}
.tool-card__icon > svg {
  width: 100%;
  fill: #fff;
}
.tool-card__heading {
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 8px;
}
.tool-card__description {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  line-height: 28px;
  text-align: center;
}
.tool-card:hover > .tool-card__icon {
  top: -8px;
}
@media (min-width: 425px) {
  .tool-card {
    display: flex;
  }
  .tool-card__icon {
    flex-shrink: 0;
    margin: 0 24px 0 0;
  }
  .tool-card__icon > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .tool-card__heading, .tool-card__description {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .tool-card__icon {
    width: 56px;
  }
}
@media (min-width: 1200px) {
  .tool-card__icon {
    margin-right: 40px;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.article-card {
  margin-bottom: 64px;
}
.article-card__main-link {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.article-card__image {
  border-radius: 4px;
  overflow: hidden;
}
.article-card__heading {
  font-size: 18px;
  font-weight: bold;
  margin-top: 24px;
  color: #000;
}
.article-card__summary {
  margin-top: 12px;
  color: #343434;
  font-size: 14px;
}
.article-card__category {
  position: relative;
  z-index: 2;
  text-decoration: none;
  color: #ab3b3f;
  font-size: 14px;
}
.article-card__category:hover {
  color: #000;
}
.article-card__date {
  font-size: 14px;
}
.article-card__more {
  display: inline-block;
  font-size: 14px;
  margin-top: 20px;
  border-bottom: 2px solid;
  color: #221b1d;
}
.article-card__group {
  margin: 12px 0 20px;
  padding: 8px 12px;
  border-radius: 2px;
  background-color: #efefef;
}
.article-card__group > .article-card__date {
  display: inline-block;
  margin-right: 8px;
}
.article-card__main-link:hover ~ .article-card__details > .article-card__heading {
  color: #000;
}
.article-card__main-link:hover ~ .article-card__details > .article-card__summary {
  color: #343434;
}
.article-card__main-link:hover ~ .article-card__details > .article-card__more {
  color: #000;
}
.article-card__heading + .article-card__date {
  margin-top: 12px;
}
@media (min-width: 768px) {
  .article-card {
    margin-bottom: 32px;
  }
}
@media (min-width: 1200px) {
  .article-card {
    display: flex;
  }
  .article-card__image-container {
    flex-basis: 230px;
    flex-shrink: 0;
    margin-right: 16px;
  }
  .article-card__heading {
    margin-top: 16px;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
@media (min-width: 768px) {
  #tools-story.section .tool-card:nth-child(1),
#tools-story.section .tool-card:nth-child(2) {
    margin-bottom: 72px;
  }
}
@media (min-width: 1200px) {
  #tools-story.section .tool-card:nth-child(1),
#tools-story.section .tool-card:nth-child(2) {
    margin-bottom: 96px;
  }
}

#tools-story.section .tool-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#tools-story.section .tool-card__icon {
  width: 70px;
  height: 70px;
  margin: 0;
}
#tools-story.section .tool-card__details {
  margin-top: 16px;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
#map-story {
  padding-bottom: 12px;
}